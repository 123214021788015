import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import BtnWhite from '../components/Botones/BtnWhite'
import { SEOComponent } from '../components/SEO/SEOComponent'

const HomePage = () => {
  return (
    <main className="full-w bg-gradient-to-r from-main to-second py-20 sm:py-0">
      <section className="sm:flex sm:flex-col sm:items-center sm:justify-center text-white sm:h-screen sm:w-screen">
        <h1 className="title text-center">Zume</h1>
        <p className='text-center'>
          Hemos evolucionado a una empresa emprendedora que está construyendo
          nuevas marcas
        </p>
        <h2 className='text-center mt-10 sm:mt-0'>Nuestras marcas</h2>
        <section className="sm:grid sm:grid-cols-2 sm:gap-8 pt-2 pb-10 sm:py-10 sm:w-s">
          <div className="card">
            <StaticImage
              src="../assets/images/LogosMarcas/EduardVelazquez.png"
              alt="EduardVelazquez"
            />
            <h3>EduardVelazquez</h3>
            <p>
              Marca personal de Eduardo Velázquez, quien tiene como misión
              ayudarte a crecer tu negocio a través de datos.
            </p>
            <BtnWhite
              full={true}
              url="https://www.eduardvelazquez.com"
              cta="Ver más"
            />
          </div>
          <div className="card">
            <StaticImage
              src="../assets/images/LogosMarcas/Zumetrics.png"
              alt="Zumetrics"
            />
            <h3>Zumetrics</h3>
            <p>
              Firma de investigación de mercados e inteligencia de negocios para
              la toma de decisiones estratégicas.
            </p>
            <BtnWhite
              full={true}
              url="https://www.zumetrics.co"
              cta="Ver más"
            />
          </div>          
        </section>
      </section>
    </main>
  )
}

export default HomePage

export const Head = () => (
  <SEOComponent
    title="Zume"
    type="Page"
    featuredImage=""
    robots="index, follow"
    description="Hemos evolucionado a una empresa emprendedora que está construyendo
          nuevas marcas"
    isCanonical={false}
    canonicalUrl={null}
    dateCreated={null}
    keywords="Zume"
  />
)
