import React from 'react'

const BtnWhite = ({ url, cta, full }) => {
  let filterFull
  if (full === 'true' || full === true) {
    filterFull = 'btn-white w-full'
  } else {
    filterFull = 'btn-white'
  }
  return (
    <a href={url} rel="noopener noreferrer" target='_blank'>
      <button className={filterFull}>{cta}</button>
    </a>
  )
}

export default BtnWhite
